<template>
  <div class="text-center p-4">
    <div>
      <h1 class="text-2xl font-bold text-primary">{{ catalog.name }}</h1>
      <p class="text-sm mb-4">{{ catalog.description }}</p>
    </div>
    <LoadingVue v-if="fetching" class="text-primary h-20 mx-auto" />
    <div v-else class="grid sm:grid-cols-6 grid-cols-4 gap-x-2 gap-y-4 mb-4">
      <ListItem v-for="(item, index) in items()" :key="index" :item="item" />

      <nuxt-link :to="localePath('/order')" custom v-slot="{ navigate }">
        <div @click="navigate" class="cursor-pointer">
          <div
            class="aspect-square rounded bg-main mb-2 flex justify-center items-center"
          >
            <span class="text-white font-bold text-2xl">More</span>
          </div>
          <p class="text-xs text-dove-gray">更多</p>
        </div>
      </nuxt-link>
    </div>
  </div>
</template>

<script>
import ListItem from "@/components/product/ListItem.vue";
import LoadingVue from "@luminexs/components/icons/Loading.vue";
import {mapActions, mapGetters} from 'vuex'

export default {
  components: {
    ListItem,
    LoadingVue
  },
  props: {
    catalog: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fetching: false,
    };
  },
  methods: {
    ...mapActions('app', ['setRecommend']),
    fetch() {
      if (this.fetching || this.items().length) return;
      this.fetching = true;
      return this.$axios
        .get(`/api/catalogs/${this.catalog.no}/recommend`)
        .then(({ data }) => {
          this.setRecommend({
            no: ''+this.catalog.no,
            items: data.data,
          });
          this.fetching = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    items() {
      return this.recommend[this.catalog.no+''] || [];
    }
  },
  computed: {
    ...mapGetters({
      recommend: 'app/recommend'
    }),
    
  },
  async created() {
    await this.fetch();
  },
};
</script>

<style lang="scss" scoped></style>
