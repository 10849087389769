<template>
  <div class="cursor-pointer mb-4" @click="show(item)">
    <div class="aspect-square rounded mb-2">
      <img
        :src="item.image || '/imgs/no_photo.jpg'"
        class="w-full h-full object-contain rounded"
      />
    </div>
    <p class="text-xs text-dove-gray line-clamp-2 text-center">
      {{ item.name }}
    </p>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import {PlusSmIcon} from "@vue-hero-icons/outline";

export default {
  components: {
    PlusSmIcon,
  },
  props: {
    item: Object,
  },
  methods: {
    ...mapActions({
      fetch: "cart/fetch",
      show: "specification/show",
    }),

    async addToOrder() {
      if (!this.item.buyable) return false;
      if (this.isGuest()) return;
      try {
        await this.$axios.post("/api/cart", {
          product: this.item.id,
          variations: [],
          quantity: 1,
        });
        this.fetch();
        this.$loader.success("已加入購物車");
      } catch (e) {
        this.handleErrorsMessage(e);
      }
    },
    add() {
      if (this.item.once_for_each_only) return this.show(this.item);

      this.addToOrder();
    },
  },
};
</script>

<style scoped>
.min-h-65px {
  min-height: 65px;
}
</style>
